<template>
  <div class="grid grid-cols-1 lg:grid-cols-6 gap-2 place-content-start">
    <div class="flex flex-col">
      <span class="font-bold">Tipo de egreso:</span>
      <Dropdown v-model="expenseTypeSelected" :options="expensesTypes" optionLabel="name" optionValue="id"
                placeholder="Seleccione un tipo" @change="onChangeExpensesTypes"/>
    </div>
    <!-- <div class="flex flex-col">
      <span class="font-bold">N° Documento:</span>
      <InputText type="text" placeholder="Digite el N° Documento" v-model="numberDoc" @keyup="onChangeNumberDoc"/>
    </div> -->
    <div class="flex items-end justify-center">
      <Button label="Limpiar filtro"
              class="mr-2 p-button-info"
              @click="clearFilter"
      />
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Gastos:</span>
      <span>{{ $h.formatCurrency(storeDetailExp) }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Registros:</span>
      <span>{{ getExpenses.count }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Saldo restante:</span>
      <span>{{ $h.formatCurrency(getDifference) }}</span>
    </div>
<!--    <div class="flex items-end justify-center">-->
<!--      <Button label="Agregar Egreso"-->
<!--              icon="pi pi-th-large"-->
<!--              class="mr-2 p-button-info"-->
<!--              @click="dialogAddExpense = !dialogAddExpense"-->
<!--      />-->
<!--    </div>-->
  </div>

  <Dialog v-model:visible="dialogAddExpense" :style="{width: '450px'}" header="Agregar Egreso" :modal="true"
          class="p-fluid">
    <div class="field">
      <label for="concept">Tipo de egreso</label>
      <Dropdown v-model="expense.expenseType" :options="expensesTypes" optionLabel="name" optionValue="id"
                placeholder="Seleccione un tipo"/>
    </div>
    <div class="field mt-2">
      <label for="concept">Concepto</label>
      <InputText id="concept"
                 v-model.trim="expense.concept"
                 required="true"
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field mt-2">
      <label for="amount">Valor</label>
      <InputNumber id="amount" v-model="expense.amount" mode="currency" currency="COP" locale="es-CO" :maxFractionDigits="0"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="dialogAddExpense = !dialogAddExpense"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveExpense"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import storeExpenses from '../../../../stores/storeExpenses'
import storeHeader from '../../../../stores/storeHeader'
import { computed, ref, onMounted } from 'vue'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'
import { helper } from '../../../../../../../utils/helper'

export default {
  name: 'homeTabsEgresosHeader',
  setup () {
    const toast = useToast()
    const paginator = computed(() => storeExpenses.getters.getPaginator)
    const numberDoc = ref(null)
    const expenseTypeSelected = ref(null)
    const expensesTypes = computed(() => storeExpenses.getters.getTypesExpenses)
    const storeDetailExp = computed(() => storeHeader.getters.getDetailExp)
    const getExpenses = computed(() => storeExpenses.getters.getExpenses)
    const getDifference = computed(() => storeExpenses.getters.getDifference)
    const dialogAddExpense = ref(false)
    const expense = ref({
      concept: '',
      expenseType: null,
      amount: 0
    })
    const route = useRoute()

    const clearFilter = () => {
      if (numberDoc.value !== null || expensesTypes.value !== null) {
        numberDoc.value = null
        expenseTypeSelected.value = null
        storeExpenses.dispatch('searchIncomes', { limit: 8, offset: 0 })
        storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
      }
    }

    const onChangeExpensesTypes = () => {
      storeExpenses.dispatch('searchExpenses', {
        limit: 8,
        offset: 0,
        expenseType: expenseTypeSelected.value,
        idPettyCashIncome: route.params.id
        // numberDoc: numberDoc.value
      })
      storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const onChangeNumberDoc = () => {
      storeExpenses.dispatch('searchExpenses', {
        limit: 8,
        offset: 0,
        expenseType: expenseTypeSelected.value,
        numberDoc: numberDoc.value
      })
      storeExpenses.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const saveExpense = () => {
      if (expense.value.expenseType !== null && expense.value.concept !== '' && expense.value.amount > 0) {
        console.log(getDifference.value)
        if (expense.value.amount <= getDifference.value) {
          storeExpenses.dispatch('saveExpense', {
            expenseType: expense.value.expenseType,
            concept: expense.value.concept,
            amount: expense.value.amount,
            idPettyCashIncome: route.params.id
          }).then(() => {
            storeExpenses.dispatch('searchExpenses', {
              limit: paginator.value.rows,
              offset: paginator.value.first,
              expenseType: expenseTypeSelected.value,
              numberDoc: numberDoc.value,
              idPettyCashIncome: route.params.id
            })
            dialogAddExpense.value = false
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se agrego el egreso correctamente', life: 3000 })
            storeExpenses.dispatch('gettingDifference', { id: route.params.id })
            storeHeader.dispatch('gettingCashBalance')
            storeHeader.dispatch('gettingTotalExpenses')
            storeHeader.dispatch('gettingExpensesDetail', { idPettyCashIncome: route.params.id })
            expense.value.expenseType = null
            expense.value.concept = ''
            expense.value.amount = 0
          })
        } else {
          Swal.fire(
            'Advertencia',
            `El valor supera el saldo restante del ingreso. (${helper.formatCurrency(getDifference.value)})`,
            'warning'
          )
        }
      } else {
        Swal.fire(
          'Advertencia',
          'Todos los campos son obligatorios',
          'warning'
        )
      }
    }

    onMounted(() => {
      storeExpenses.dispatch('searchTypesExpenses')
      storeExpenses.dispatch('searchExpenses', { limit: 8, offset: 0, idPettyCashIncome: route.params.id })
      storeExpenses.dispatch('gettingDifference', { id: route.params.id })
      storeHeader.dispatch('gettingExpensesDetail', { idPettyCashIncome: route.params.id })
    })

    return {
      numberDoc,
      expensesTypes,
      expenseTypeSelected,
      storeDetailExp,
      getExpenses,
      dialogAddExpense,
      expense,
      getDifference,
      clearFilter,
      onChangeExpensesTypes,
      onChangeNumberDoc,
      saveExpense
    }
  }
}
</script>

<style>
.swal2-container {
  z-index: 9999;
}
</style>

import { createStore } from 'vuex'

import ExpensesService from '../services/ExpensesService'
import ExpensesTypesService from '../services/ExpensesTypesService'
import http from '@/libs/http'

const _ExpensesService = new ExpensesService()
const _ExpensesTypesService = new ExpensesTypesService()

const storeIncomes = {
  state: {
    typesExpenses: [],
    expenses: {
      count: 0,
      rows: []
    },
    paginator: {
      rows: 8,
      first: 0
    },
    difference: 0,
    regimen: [
      {
        id: null,
        name: 'Seleccione una opcion'
      },
      {
        id: 1,
        name: 'COMUN'
      },
      {
        id: 2,
        name: 'SIMPLIFICADO'
      }
    ]
  },
  mutations: {
    setTypesExpenses (state, payload) {
      state.typesExpenses = payload
    },
    setExpenses (state, payload) {
      state.expenses = {
        ...payload
      }
    },
    setPaginator (state, payload) {
      state.paginator = {
        ...payload
      }
    },
    setDifference (state, payload) {
      state.difference = parseInt(payload)
    }
  },
  actions: {
    searchTypesExpenses ({ commit }) {
      _ExpensesTypesService.getExpensesTypes().then(({ data }) => {
        data.unshift({
          id: null,
          name: 'Seleccione un tipo'
        })
        commit('setTypesExpenses', data)
      }).catch(error => {
        console.log(error)
      })
    },
    searchExpenses ({ commit }, payload) {
      _ExpensesService.getExpenses(payload).then(({ data }) => {
        commit('setExpenses', data)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingDifference ({ commit }, { id }) {
      _ExpensesService.getDifference(id).then(({ data }) => {
        commit('setDifference', data.difference)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingPaginator ({ commit }, payload) {
      commit('setPaginator', payload)
    },
    saveExpense ({ commit }, payload) {
      return _ExpensesService.saveExpense(payload).then(({ data }) => {
        return data
      }).catch(error => {
        console.log(error)
      })
    },
    saveExpenseFull ({ commit }, payload) {
      return _ExpensesService.saveExpenseFull(payload).then(({ data }) => {
        return data
      }).catch(error => {
        console.log(error)
      })
    },
    deleteExpense ({ commit }, id) {
      return _ExpensesService.deleteExpense(id).then(({ data }) => {
        return data
      }).catch(error => {
        console.log(error)
      })
    },
    getPdf ({ commit }, { ruta }) {
      return new Promise((resolve) => {
        const baseURL = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH_NEWGASANDOIL
        const url = `${baseURL}/visualizar-pdf`
        const res = http.getUri({ url, params: { ruta } })
        resolve(res)
      })
    }
  },
  getters: {
    getTypesExpenses: state => state.typesExpenses,
    getExpenses: state => state.expenses,
    getPaginator: state => state.paginator,
    getDifference: state => state.difference,
    getRegimen: state => state.regimen
  }
}
const store = createStore(storeIncomes)

export default {
  namespaced: true,
  ...store
}

<template>
  <div class="p-4">
    <Header/>
    <Table/>
  </div>
</template>

<script>
import Header from './header'
import Table from './table'

export default {
  name: 'tabEgresos',
  components: {
    Header,
    Table
  }
}
</script>

<style scoped>

</style>

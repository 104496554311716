import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH_NEWGASANDOIL

export default class IncomesTypesService {
  getExpensesTypes () {
    return http.get(`${baseUrl}/expenses-types`, {
      headers: {
        loading: true
      }
    })
  }
}
